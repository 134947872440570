import * as React from "react";

import {PageProps} from "gatsby";

import "yet-another-react-lightbox/styles.css";
import {Layout} from "../components/Layout";
import {StaticImage} from "gatsby-plugin-image";

export default function Experiment(data: PageProps<String>) {
    const [open, setOpen] = React.useState(false);
    const toggleLightBox = () => {
        setOpen(!open)
    }

    return   (
        <div>
            {data.data}
        </div>

    )

};

